import React from 'react';
import './AboutSection.css';

const AboutSection = () => {
  return (
    <section className="section section-lg" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 justify-content-center">
            <h1 className="text-center fade-in about-title">About Us</h1>
            <p style={{width: "80%", paddingLeft: "10%"}} className="text-center fade-in about-text">
              At HAI Consulting, we believe that AI has the potential to revolutionize the way businesses operate. Our mission is to demystify AI, making its powerful capabilities accessible to organizations of all sizes. We focus on designing custom systems built around the people that will use them. Using AI, we are able to streamline this process and deliver with quick turn arounds. <br /><br /> Here at HAI, our focus is on delivering the most value for your money spent with us, so your time can be spent on the parts of your business you love doing. 
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;