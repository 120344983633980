import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './ToolsSection.css';
import genvoicePreview from '../assets/img/tools/genvoice-preview.png';

const ToolsSection = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Listen for body margin changes to sync with navbar state
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'style') {
          setIsMenuOpen(document.body.style.marginRight === '15px');
        }
      });
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['style']
    });

    return () => observer.disconnect();
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5
      }
    },
    hover: {
      scale: 1.02,
      boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
      transition: {
        duration: 0.3
      }
    }
  };

  const imageVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 100,
        duration: 0.8
      }
    }
  };

  return (
    <motion.section 
      id="tools" 
      className={`section ${isMenuOpen ? 'menu-open' : ''}`}
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
    >
      <div className="container">
        <motion.div 
          className="row justify-content-center"
          variants={itemVariants}
        >
          <div className="col-lg-8 text-center">
            <motion.h2 
              className="mb-4"
              variants={itemVariants}
            >
              Our Tools
            </motion.h2>
            <motion.p 
              className="text-muted mb-5"
              variants={itemVariants}
            >
              Explore our suite of AI-powered tools designed to enhance your business operations.
            </motion.p>
          </div>
        </motion.div>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <motion.div 
              className="tool-card"
              variants={cardVariants}
              whileHover="hover"
            >
              <motion.div 
                className="tool-preview"
                variants={imageVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                <a 
                  href="https://haiconsulting.github.io/genvoice-live" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <motion.img 
                    src={genvoicePreview} 
                    alt="GenVoice Live Preview" 
                    className="img-fluid"
                  />
                </a>
              </motion.div>
              <motion.div 
                className="tool-info"
                variants={itemVariants}
              >
                <motion.h3
                  variants={itemVariants}
                >
                  GenVoice
                </motion.h3>
                <motion.p
                  variants={itemVariants}
                >
                  A Quick & Easy Invoice generation tool for small business owners. Also can create statements of work with hourly rates, and has a Gen-AI template generation component.
                </motion.p>
                <motion.a 
                  href="https://haiconsulting.github.io/genvoice-live" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="btn btn-primary"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                >
                  Try it now
                </motion.a>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default ToolsSection; 