import { withCors } from './corsConfig';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:4000/functions/api';

export const createThread = async () => {
  try {
    const url = `${API_URL}/threads`.replace(/\s+/g, '').replace(/#.*$/, '');
    
    // Try a test request first with no-cors mode for health check
    await fetch(`${API_URL}/health`, {
      mode: 'no-cors',
      headers: withCors({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: withCors({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': window.location.origin
      }),
      mode: 'cors',
      credentials: 'omit'
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const sendMessage = async (threadId, content) => {
  if (!content || !content.trim()) {
    throw new Error('Message content cannot be empty');
  }

  try {
    const url = `${API_URL}/threads/${threadId}/messages`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: withCors({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': window.location.origin
      }),
      body: JSON.stringify({ content: content.trim() }),
      mode: 'cors',
      credentials: 'omit'
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const getMessages = async (threadId) => {
  if (!threadId) {
    throw new Error('Thread ID is required');
  }

  try {
    const url = `${API_URL}/threads/${threadId}/messages`;
    
    const response = await fetch(url, {
      headers: withCors({
        'Accept': 'application/json',
        'Origin': window.location.origin
      }),
      mode: 'cors',
      credentials: 'omit'
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};