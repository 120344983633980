import React, { useState, useEffect, useRef } from 'react';
import { Send, Maximize2, Minimize2, X } from 'lucide-react';
import './AIChat.css';
import chatGptLogo from '../assets/img/chatgpt.png';
import byteLion from '../assets/img/ByteLion.png';
import { createThread, sendMessage, getMessages } from '../utils/openaiConfig';

const sanitizeMessage = (text) => {
  return text
    // Remove source markers with more specific pattern
    .replace(/\d+:\d+source|\d+source|【\d+:\d+†source】/g, '')
    // Convert numbered lists to proper format
    .replace(/(\d+)\.\s+/g, '<strong>$1.</strong> ')
    // Convert **text** to proper HTML/markdown formatting
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    // Remove other problematic Unicode characters
    .replace(/[\u{0080}-\u{FFFF}]/gu, '')
    // Clean up any double spaces and trim
    .replace(/\s+/g, ' ')
    .trim();
};

const renderFormattedMessage = (text) => {
  return {
    __html: text
      .split('\n')
      .map(line => `<p>${line}</p>`)
      .join('')
  };
};

const AIChat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [threadId, setThreadId] = useState(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  
  const toggleTooltip = () => setShowTooltip(!showTooltip);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  

  useEffect(() => {
    const initChat = async () => {
      try {
        // Create a new thread
        const thread = await createThread();
        setThreadId(thread.id);

        // Get initial message
        const messages = await getMessages(thread.id);
        if (messages.data && messages.data.length > 0) {
          setMessages([{ 
            text: messages.data[0].content[0].text.value, 
            sender: 'ai' 
          }]);
        } else {
          setMessages([{ 
            text: 'Hello! You got HaiGuy here. Got any questions about HAI Consulting?', 
            sender: 'ai' 
          }]);
        }
      } catch (error) {
        console.error('Error initializing chat:', error);
      }
    };
    initChat();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async (message = input) => {
    if (!message || typeof message !== 'string' || message.trim() === '' || !threadId) {
      console.log('Invalid message or missing threadId:', { message, threadId });
      return;
    }

    const userMessage = { text: message, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);

    try {
      // Send message and get response
      const response = await sendMessage(threadId, message);
      
      // Add the latest assistant message
      if (response.data && response.data.length > 0) {
        const latestMessage = response.data[0];
        if (latestMessage.role === 'assistant') {
          const sanitizedText = sanitizeMessage(latestMessage.content[0].text.value);
          setMessages(prevMessages => [
            ...prevMessages,
            { 
              text: sanitizedText,
              sender: 'ai' 
            }
          ]);
        }
      }
    } catch (error) {
      console.error('Error in chat interaction:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { 
          text: 'Sorry, there was an error processing your message.', 
          sender: 'ai' 
        }
      ]);
    } finally {
      setIsTyping(false);
    }
  };

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
    if (isCollapsed) setIsCollapsed(false);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    if (isMaximized) setIsMaximized(false);
  };

  if (isCollapsed) {
    return (
      <button style={{zIndex: "100"}} onClick={toggleCollapse} className="haiguy-collapsed-button" aria-label="Open chat">
        <img src={byteLion} alt="ByteLion Chat Icon"/>
      </button>
    );
  }

  return (
    <div className={`haiguy-chat-container ${isMaximized ? 'haiguy-maximized' : ''}`}>
      <div className="haiguy-chat-window">
        <div className="haiguy-chat-header">
          <div className="haiguy-chat-header-left">
            <div className="haiguy-chat-header-icon" role="img" aria-label="byteLion">
              <img style={{width: "50%"}} src={byteLion} alt="ByteLion Logo"/>
            </div>
            <h2 className="haiguy-chat-header-title">HaiGuy</h2>
          </div>
          <div className="haiguy-chat-header-controls">
            <button onClick={toggleMaximize} className="haiguy-control-button" aria-label={isMaximized ? "Minimize" : "Maximize"}>
              {isMaximized ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
            </button>
            <button onClick={toggleCollapse} className="haiguy-control-button" aria-label="Close">
              <X size={20} />
            </button>
          </div>
        </div>
        {!isCollapsed && (
          <div className="haiguy-chat-powered-by">
            <span>Powered by:</span>
            <img 
              style={{width: "8%"}}
              src={chatGptLogo} 
              alt="ChatGPT Logo" 
              className="haiguy-chat-gpt-logo" 
              title="Open AI"
              onClick={toggleTooltip}
              ref={tooltipRef}
            />
            
          </div>
        )}
        <div className="haiguy-chat-messages">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`haiguy-message ${message.sender === 'user' ? 'haiguy-message-user' : 'haiguy-message-ai'}`}
              dangerouslySetInnerHTML={renderFormattedMessage(message.text)}
            />
          ))}
          {isTyping && (
            <div className="haiguy-typing-indicator">
              Typing...
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="haiguy-input-area">
          <div className="haiguy-input-container">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
              placeholder="Type your message..."
              className="haiguy-input"
            />
            <button
              onClick={() => handleSend(input)}
              className="haiguy-send-button"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIChat;