import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './BlogPreview.css';

const BlogPreview = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    // Fetch blog posts data
    const posts = [
      {
        id: 1,
        title: "Artificial Intelligence Consulting in Atlanta",
        description: "Discover how how HAI consulting plans to help Atlanta businesses leverage AI.",
        date: "December 2, 2024",
        fileName: "atlanta-ai-consulting.html",
        imageUrl: "/images/atlanta-skyline-ai.jpg",
        readTime: "8 min read"
      },
      {
        id: 2,
        title: "Tomorrow's Marketing Strategy for SMBs",
        description: "Discover how AI is transforming marketing for small businesses in Atlanta and how your business can leverage these powerful tools.",
        date: "November 23, 2024",
        fileName: "tomorrow-marketing-strategy.html"
      },
      {
        id: 3,
        title: "Top 10 AI Consulting Trends 2024",
        description: "Explore the latest trends in AI consulting and how they're shaping the future of business.",
        date: "September 1, 2024",
        fileName: "top-10-ai-consulting-trends-2024.html"
      },
      {
        id: 4,
        title: "Artificial Intelligence Consulting",
        description: "Learn about our comprehensive AI consulting services and how they can transform your business.",
        date: "September 2, 2024",
        fileName: "artificial-intelligence-consulting.html"
      },
      {
        id: 5,
        title: "AI Consulting Services",
        description: "Discover our full suite of AI consulting services designed to help businesses navigate the complexities of AI adoption.",
        date: "September 3, 2024",
        fileName: "ai-consulting-services.html"
      }
    ];
    setBlogPosts(posts);
  }, []);

  const cardVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.05, transition: { duration: 0.2 } }
  };

  // Show only the 3 most recent posts in preview
  const recentPosts = blogPosts.slice(0, 3);

  return (
    <section className="section section-lg section-safe" id="blog">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <motion.h1 
              className="text-center text-white"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              Latest Insights
            </motion.h1>
            <motion.p 
              className="text-center mb-5 text-white"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
            >
              Explore our latest thoughts on AI and technology
            </motion.p>
            <div className="row row-grid justify-content-center">
              {recentPosts.map((post, index) => (
                <motion.div 
                  key={post.id}
                  className="col-lg-4"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3 + (index * 0.1) }}
                >
                  <motion.div 
                    className="blog-card"
                    variants={cardVariants}
                    initial="initial"
                    whileHover="hover"
                  >
                    <a href={`/blogs/${post.fileName}`} target="_blank" rel="noopener noreferrer">
                      <h3>{post.title}</h3>
                      <p className="blog-date">{post.date}</p>
                      <p>{post.description}</p>
                      <span className="read-more">Read More →</span>
                    </a>
                  </motion.div>
                </motion.div>
              ))}
            </div>

            {/* View All Blogs Button */}
            <motion.div 
              className="text-center mt-5"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.5 }}
            >
              <Link to="/blog" className="btn btn-primary btn-lg">
                View All
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPreview;