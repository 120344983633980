import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './BlogPreview.css';

const BlogPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  

  useEffect(() => {
    // Define blog posts
    const posts = [
      {
        id: 1,
        title: "Artificial Intelligence Consulting in Atlanta",
        description: "Discover how HAI Consulting is transforming Atlanta businesses through tailored AI solutions. Learn about our human-centered approach to AI consulting and how we help SMEs leverage artificial intelligence for sustainable growth.",
        date: "December 2, 2024",
        fileName: "atlanta-ai-consulting.html"
      },
      {
        id: 2,
        title: "Tomorrow's Marketing Strategy for SMBs",
        description: "Discover how AI is transforming marketing for small businesses in Atlanta and how your business can leverage these powerful tools.",
        date: "November 23, 2024",
        fileName: "tomorrow-marketing-strategy.html"
      },
      {
        id: 3,
        title: "Top 10 AI Consulting Trends 2024",
        description: "Explore the latest trends in AI consulting and how they're shaping the future of business.",
        date: "September 1, 2024",
        fileName: "top-10-ai-consulting-trends-2024.html"
      },
      {
        id: 4,
        title: "Artificial Intelligence Consulting",
        description: "Learn about our comprehensive AI consulting services and how they can transform your business.",
        date: "September 2, 2024",
        fileName: "artificial-intelligence-consulting.html"
      },
      {
        id: 5,
        title: "AI Consulting Services",
        description: "Discover our full suite of AI consulting services designed to help businesses navigate the complexities of AI adoption.",
        date: "September 3, 2024",
        fileName: "ai-consulting-services.html"
      }
    ];
    setBlogPosts(posts);
  }, []);

  const cardVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.05, transition: { duration: 0.2 } }
  };

  return (
    <section className="section section-lg section-safe blog-page">
      <div className="container">
        <motion.h1 
          className="text-center text-white mb-5"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          All Blog Posts
        </motion.h1>
        <div className="row row-grid justify-content-center">
          {blogPosts.map((post, index) => (
            <motion.div 
              key={post.id}
              className="col-lg-4 mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <motion.div 
                className="blog-card"
                variants={cardVariants}
                initial="initial"
                whileHover="hover"
              >
                <a href={`/blogs/${post.fileName}`} target="_blank" rel="noopener noreferrer">
                  <h3>{post.title}</h3>
                  <p className="blog-date">{post.date}</p>
                  <p>{post.description}</p>
                  <span className="read-more">Read More →</span>
                </a>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogPage;